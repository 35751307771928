import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Vikhe Patil Senior Secondary School, Ahmednagar",
  address: "Ahmednagar, Maharashtra",
  client: "Padamshri Dr. Vithal Rao Vikhe Patil Foundation",
  area: "1,50,000 Sq.Ft. ",
  project: "Vikhe Patil Senior Secondary School, Ahmednagar",
  category: "Institutions | Architecture | School",
  status: "Under Design Approval",
  backlink: "/projects/institutions/",
};

export default class Projectvpsssahmednagar extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Institutional Projects | Vikhe Patil Senior Secondary School, Ahmednagar"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore the design concept and architectural layout of an upcoming senior secondary school in Ahmednagar."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
